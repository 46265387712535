<template>
  <CRow>
    <div class="text-right col-12 mb-3">
      <CButton
        type="submit"
        class="right-create"
        color="success"
        @click="modalShow = true"
        >Create User</CButton
      >
    </div>
    <table class="table table-dark">
      <thead>
        <tr>
          <th scope="col">Email</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item['id']">
          <td>{{ item["email"] }}</td>
          <td>
            <CButton
              class="right-create"
              size="sm"
              color="primary"
              @click="onClickEdit(item['id'])"
              >Edit</CButton
            >&nbsp;
            <CButton
              class="right-create"
              size="sm"
              color="danger"
              @click="deleteUser(item['id'])"
              >Delete</CButton
            >
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-end w-100 mb-3">
      <nav aria-label="Page navigation example" class="mr-3">
        <ul class="pagination mb-0">
          <li class="page-item">
            <a
              class="page-link"
              @click="onLoad('prev')"
              aria-label="Previous"
              id="prev"
            >
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li
            class="page-item"
            :class="{ active: t === current_page }"
            v-for="t in totalPage"
            :key="t"
          >
            <a class="page-link" @click="onPageLoad(t)">{{ t }}</a>
          </li>
          <li class="page-item">
            <a
              class="page-link"
              @click="onLoad('next')"
              aria-label="Next"
              id="next"
            >
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
      <select v-model="selected" @change="onSelect" class="px-3">
        <option :selected="true">5</option>
        <option>10</option>
        <option>50</option>
      </select>
    </div>

    <user-edit-form
      v-if="modalShow"
      :modalShow="modalShow"
      :id="user_id"
      @saveUser="saveUserData"
      :on-hide-modal="onHideModal"
    ></user-edit-form>
  </CRow>
</template>

<script>
import { getUsers, deleteUser } from "@/api/user";
import UserEditForm from "./UserEditForm.vue";

export default {
  name: "Users",
  components: {
    UserEditForm,
  },
  data: () => {
    return {
      items: [],
      fields: [
        { key: "name" },
        { key: "mobile" },
        { key: "email" },
        { key: "actions" },
      ],
      modalShow: false,
      user_id: 0,
      perPage: 5,
      prev: "",
      next: "",
      last: "",
      totalData: "",
      totalPage: "",
      current_page: 1,
      selected: 5,
      authId: "",
    };
  },
  created() {
    this.authId = this.$store.state.user.user.id;
    getUsers(1, this.perPage)
      .then((res) => {
        this.items = (res.data && res.data.data.data) || [];
        // this.removeAuthUser(this.items);
        this.prev = res.data.data.prev_page_url;
        if (this.prev === null) {
          document.getElementById("prev").classList.add("disable_a_href");
        }
        this.last = res.data.data.last_page_url;
        if (this.next === null) {
          document.getElementById("next").classList.add("disable_a_href");
        }
        this.next = res.data.data.next_page_url;
        this.perPage = res.data.data.per_page;
        this.totalData = res.data.data.total;
        this.totalPage = Math.ceil(this.totalData / this.perPage);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  },
  paginationProps: {
    align: "center",
    doubleArrows: false,
    previousButtonHtml: "prev",
    nextButtonHtml: "next",
  },
  methods: {
    // removeAuthUser(data) {
    //   var index = data.findIndex((x) => x.id == this.authId);
    //   index >= 0 && this.items.splice(index, 1);
    // },
    onSelect(event) {
      let value = event.target.value;
      getUsers(1, value)
        .then((res) => {
          this.items = (res.data && res.data.data.data) || [];
          //   this.removeAuthUser(this.items);
          this.isLoading = false;
          this.perPage = res.data.data.per_page;
          this.prev = res.data.data.prev_page_url;
          this.next = res.data.data.next_page_url;
          if (this.prev === null) {
            document.getElementById("prev").classList.add("disable_a_href");
          }
          this.last = res.data.data.last_page_url;
          if (this.next === null) {
            document.getElementById("next").classList.add("disable_a_href");
          }
          this.totalData = res.data.data.total;
          this.totalPage = Math.ceil(this.totalData / this.perPage);
        })
        .catch((error) => {
          // reject(error)
          console.log(error, "error");
        });
    },
    onLoad(value) {
      if (value === "prev") {
        if (this.current_page != 1) this.current_page = this.current_page - 1;
      } else if (this.current_page < this.totalPage) {
        this.current_page = this.current_page + 1;
      }
      console.log(this.perPage, "=-=-=-=-=-");
      this.onPageLoad(this.current_page, this.perPage);
    },
    onPageLoad(page) {
      getUsers(page, this.perPage)
        .then((res) => {
          this.items = (res.data && res.data.data.data) || [];
          //   this.removeAuthUser(this.items);
          this.perPage = res.data.data.per_page;
          this.prev = res.data.data.prev_page_url;
          this.last = res.data.data.next_page_url;
          if (this.prev !== null) {
            document.getElementById("prev").classList.remove("disable_a_href");
          } else {
            document.getElementById("prev").classList.add("disable_a_href");
          }
          if (this.next !== null) {
            document.getElementById("next").classList.remove("disable_a_href");
          } else {
            document.getElementById("next").classList.add("disable_a_href");
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
      this.current_page = page;
    },

    onClickEdit(id) {
      this.modalShow = true;
      this.user_id = id;
    },
    onHideModal() {
      this.modalShow = !this.modalShow;
      this.user_id = 0;
    },
    saveUserData(data, id = null) {
      if (id) {
        var foundIndex = this.items.findIndex((x) => x.id == id);
        this.items[foundIndex] = data;
      } else {
        this.items = data.data;
        this.current_page = 1;
        // this.removeAuthUser(this.items);
        this.perPage = data.per_page;
        this.totalData = data.total;
        this.totalPage = Math.ceil(this.totalData / this.perPage);
      }
    },
    deleteUser(id) {
      if (confirm("Do you really want to delete user?")) {
        deleteUser(id)
          .then((res) => {
            console.log(res.data.data, "=====");
            this.items = (res.data && res.data.data.data) || [];
            // this.removeAuthUser(this.items);
            this.$notify({
              group: "notify",
              type: "success",
              text: res.data.message,
            });
            this.current_page = 1;
            this.perPage = res.data.data.per_page;
            this.totalData = res.data.data.total;
            this.totalPage = Math.ceil(this.totalData / this.perPage);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.disable_a_href {
  pointer-events: none;
}
.active {
  background-color: blue;
}
li {
  cursor: pointer;
}
</style>
